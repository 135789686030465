import { render, staticRenderFns } from "./inheritorIndex.vue?vue&type=template&id=37c1eee2&scoped=true&"
import script from "./inheritorIndex.vue?vue&type=script&lang=js&"
export * from "./inheritorIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c1eee2",
  null
  
)

export default component.exports