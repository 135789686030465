<template>
  <el-dialog
    top="20vh"
    width="600px"
    class="el-dialog-cus"
    center
    :visible="visible"
    append-to-body
    :close-on-click-modal="true"
  >
    <iframe class="pop-img-box"
     
      target="_blank" :src="video" style="width: 100%;"></iframe>
	  <el-link class="custon-close" @click.stop.prevent="cancel"><i class="el-icon-close"></i></el-link>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
   video:String
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss">
.el-dialog__header {
  display: none;
}
.el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
.el-dialog {
  margin-bottom: 0px !important;
  background: transparent !important;
}
</style>
