<template>
	<div class="page-content-box" :style="{ background: 'url(' + bgImg + ') no-repeat top center', backgroundSize: 'cover' }">
		<head-main class="head-white no-fixed"></head-main>
		<div class="custon-bread ">
			<!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>县市地图</el-breadcrumb-item>
        <el-breadcrumb-item>{{ info.provinceCityCounty }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ info.trueName }}</el-breadcrumb-item>
      </el-breadcrumb> -->
			<!-- <el-button type="primary" icon="el-icon-plus" @click="diaa" size="small">添加</el-button> -->
		</div>
		<div class="index-w">
			<index-head :text="isFollow ? '已关注' : '加关注'" :info="info" :follow-num="followNum" :fan-num.sync="fanNum"
			 :dynamic-num="dynamicNum" :isFollow.sync="isFollow" :hide-opt="hideOpt"></index-head>
			<div class="inheritor-tab">
				<template>
					<!-- {{info}} -->
					<el-tabs class="inheritor-eltab" v-model="activeName">
						<el-tab-pane label="他的主页" name="first">
							<div class="he-index-box">
								<div class="he-l">
									<div class="he-conten">
										<h5 class="he-tit">{{ info.trueName }}简介</h5>
										<ul class="content-list">
											<li>非遗名录：{{ info.fintangibleDir }}</li>
											<li>非遗类型：{{ info.fintangibleType }}</li>
											<!-- <li>传承人级别：{{ info.inheritorLevel }}</li> -->
											<li>出生日期：{{ info.birthday }}</li>
											<li>地区：{{ info.provinceCityCounty }}</li>
											<li>性别：{{ info.sex }}</li>
										</ul>
										<!-- <img :src="artical1" /> -->
										<p v-html="info.webContent"></p>
									</div>
								</div>
								
								<div class="he-r">
									<div class="dynamic-con">
										<h5 class="he-tit">他的动态</h5>
										<ul class="dynamic-list">
											<!-- {{dynamicList}} -->
											<li v-for="(item, i) in dynamicList" :key="i">
												<!-- {{item}} -->
												<span class="head-imgs" :style="{
                            background: 'url(' + info.webImg + ') no-repeat center center',
                            backgroundSize: 'cover'
                          }"></span>
												<div class="dynamic-r">
													<h5 class="he-name-tit">
														{{ item.inheritorName
                            }}<i class="level-icon" :style="{
                                background: 'url(' + level + ') no-repeat center center',
                                backgroundSize: 'cover'
                              }"></i>
													</h5>
													<span class="dynamic-time">{{ item.createTime }}</span>
													<p>{{ item.blogContent }}</p>
													<div class="dynamic-img">
														<template v-if="item.blogType == 1">
															<div>
																<div class="more-list">
																	<ul>
																		<li v-for="(img, j) in item.bolgImgs.split(',')" :key="j">
																			<img :src="img" />
																		</li>
																	</ul>
																</div>
																<!-- <div class="img-one">
                                  <img :src="item.bolgImgs" />
                                </div> -->
															</div>
														</template>
														<div class="video-box" v-else-if="item.blogType == 2">
															<img :src="item.blogVideo" />
														</div>
													</div>
													<div class="dynamic-view-box">
														<!-- <div class="dynamic-view">
                              <i class="hand-i" @click="like(item)"></i>
                              <span>点赞</span>
                              <span>{{ item.likedNum }}</span>
                            </div> -->
														<!-- <div class="dynamic-view">
                              <i class="view-i"></i>
                              <span>浏览</span>
                              <span>{{ item.view }}</span>
                            </div> -->
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</el-tab-pane>
						<!-- <el-tab-pane label="他的相册" name="second">
              <div class="he-bg-white">
                <template>
                  <el-tabs class="el-tabs-other" v-model="activeNames">
                    <el-tab-pane label="图片和视频" name="first">
                      <ul class="photo-list">
                        <template v-for="time in Object.keys(photoList)">
                          <li v-if="time.length > 0" :key="time">
                            <h5 class="photo-time">{{ time }}</h5>
                            <ul class="photo-img-list">
                              <template v-for="item in photoList[time]">
                                <template v-for="(img, index) in item.bolgImgs.split(',')">
                                  <li
                                    :key="index"
                                    :style="{
                                      background: 'url(' + img + ') no-repeat center center',
                                      backgroundSize: 'cover'
                                    }"
                                    @click="diaa"
                                  >
                                    <div class="photo-fu">
                                <span
                                  >点赞<i>{{ photo.num }}</i></span
                                >
                                <span
                                  >浏览<i>{{ photo.view }}</i></span
                                >
                              </div>
                                  </li>
                                </template>
                              </template>
                            </ul>
                          </li>
                        </template>
                      </ul>
                    </el-tab-pane>
                  </el-tabs>
                </template>
              </div>
            </el-tab-pane> -->
					</el-tabs>
				</template>
			</div>
		</div>

		<botto-com class="bottom-white no-fixed"></botto-com>

		<pop-inheritor :shows="showss" :width="width" :height="height" @closepop="closepop" :close="close"></pop-inheritor>
		<pop-video :video="inheritorVideo" ref="popVideo"></pop-video>
	</div>
</template>
<script>
	import HeadMain from '../../components/head'
	import BottoCom from '../../components/bottom'
	import IndexHead from '../../components/indexhead'
	import PopInheritor from '../index/popInheritor'
	import popVideo from '../../components/popVideo.vue'
	export default {
		components: {
			HeadMain,
			BottoCom,
			IndexHead,
			PopInheritor,
			popVideo
		},

		data() {
			return {
				info: {
					webImg: '',
					trueName: '',
					fintangibleDir: '',
					fintangibleType: '',
					inheritorLevel: '',
					birthday: '',
					provinceCityCounty: '',
					sex: '',
					memberId: '',
					webContent: ''
				},
				inheritorVideo: "",
				hideOpt: true,
				followNum: 0,
				fanNum: 0,
				dynamicNum: 0,
				id: this.$route.query.id,
				contentId: this.$route.query.contentId,
				showss: false,
				width: '1200px',
				height: '600px',
				activeName: 'first',
				activeNames: 'first',
				isFollow: 0,
				close: true,

				bgImg: require('../../assets/images/bg5.jpg'),
				// artical1: require('../../assets/images/article-img1.jpg'),
				// artical2: require('../../assets/images/article-img2.png'),
				level: require('../../assets/images/level.png'),
				headicon: require('../../assets/images/people.png'),
				dynamicList: [],
				photoList: [
					// {
					//   time: '2020年09月',
					//   phpto: [
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     }
					//   ]
					// },
					// {
					//   time: '2020年09月',
					//   phpto: [
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     },
					//     {
					//       img: require('../../assets/images/view-img3.png'),
					//       num: '254',
					//       view: '222'
					//     }
					//   ]
					// }
				]
			}
		},
		methods: {
			like(item) {
				if (!item.liked) {
					this.axios.get('/api/portal/blog/auth/update-liked?id=' + item.id).then(res => {
						item.liked = true
						item.likedNum++
					})
				}
			},
			diaa() {
				this.showss = true
				//   this.showss== !this.showss;
			},
			closepop() {
				this.showss = false
			},
			getInheritorData() {
				this.axios
					.get('/api/portal/cont/pub-detail', {
						params: {
							id: this.id
						}
					})
					.then(res => {
						/* console.log("===================")
						console.log(res) */
						let data = res.data.inheritorData
						this.followNum = res.data.fllowerNum
						this.fanNum = res.data.friendNum
						this.isFollow = res.data.isFriend
						this.dynamicNum = res.data.blogNum
						this.hideOpt = !data.inheritorId || !data.memberId
						this.info = {
							memberId: data.memberId,
							webImg: data.webImg,
							trueName: data.trueName,
							fintangibleDir: data.fintangibleDir,
							fintangibleType: data.fintangibleType,
							inheritorLevel: data.inheritorLevel,
							birthday: this.$dayjs(data.birthday).format('YYYY-MM'),
							provinceCityCounty: data.provinceCityCounty,
							sex: data.sex,
							webContent: data.webContent
						}
						if (data.inheritorVideo) {
							this.inheritorVideo = data.inheritorVideo;
							this.$refs.popVideo.open();
						} else {
							this.inheritorVideo = ""
						}
					})
			},
			getDynamicList() {
				this.axios
					.get('/api/portal/blog/page-content', {
						params: {
							inheritorId: this.contentId,
							// inheritorId: '1325820724043841538',
						}
					})
					.then(res => {
						console.log(res); 
						/*var tempList=res.data.records
						 for (var i = 0; i < tempList.length; i++) {
							if(tempList[i].statusName=="已通过"){
								this.dynamicList.push(tempList[i])
							}
						} */
						this.dynamicList = res.data.records
					})
			},
			getPhotoList() {
				this.axios
					.get('/api/portal/blog/list-materials', {
						params: {
							inheritorId: this.contentId
						}
					})
					.then(res => {
						this.photoList = res.data
					})
			}
		},
		mounted() {
			this.getInheritorData()
			this.getDynamicList()
			this.getPhotoList()
			document.getElementsByTagName('body')[0].className = 'page-body'
		},
		beforeDestroy() {
			document.body.removeAttribute('class', 'page-body')
		}
	}
</script>
<style lang="scss" scoped></style>
